<template>
  <div id="nofind">
    <img src="../assets/img/404.png" />
    <p>抱歉，没有找到页面</p>
    <a-button type="primary" @click="$router.push('/')">返回首页</a-button>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss'>
  #nofind {
    width: 1200px;
    padding: 80px 0;
    margin: 0 auto;

    & > img {
      display: block;
      width: 338px;
      height: 143px;
      margin: 0 auto 40px;
    }

    & > p {
      text-align: center;
      font-size: 16px;
      color: #a9aeb8;
      line-height: 20px;
      margin-bottom: 40px;
    }

    .arco-btn {
      width: 200px;
      height: 40px;
      display: block;
      margin: 0 auto;
    }
  }
</style>